import React from 'react'
import moment from 'moment'

import EventsListItem from './EventsListItem'

export default ( props ) => {

	const { data } = props
	const now = moment()

	const upcomingEvents = data.filter( event => !event.eventDateEnd || moment( event.eventDateEnd ).isSameOrAfter( now, 'day' ) )
	const previousEvents = data.filter( event => moment( event.eventDateEnd).isBefore( now ) )

	return (
		<section className="events-list wrapper">

			{ !!upcomingEvents.length &&
				<>
					<div className="events-list__header">Upcoming Events</div>
					<div className="events-list__container">
						{ upcomingEvents.map( event => (
							<EventsListItem data={ event } upcoming={ true } key={ event.id } />
						))}
					</div>
				</>
			}
			{ !!previousEvents.length && 
				<>
					<div className="events-list__header"> Previous Events </div>
					<div className="events-list__container">
						{ previousEvents.map( event => (
							<EventsListItem data={ event } upcoming={ false } key={ event.id } />
						))}
					</div>
				</>
			}
		</section>
	)
}