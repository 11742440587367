import React from 'react'
import Img from 'gatsby-image'
import ReactMarkdown from 'react-markdown'
import { Link } from 'gatsby'

export default ( props ) => {

	const { data, upcoming } = props

	return (
		<div className="event-list-item">
			<div className="event-list-item__image-container">
				<Img fluid={{ ...data.eventImage.imageFile.childImageSharp.fluid, aspectRatio: 1 }} className="event-list-item__image"/>
			</div>

			<div className="event-list-item__text-container">
				<div className="event-list-item__heading">{ data.eventTitle }</div>

				<ReactMarkdown source={ data.eventIntro } className="event-list-item__description"/>

				{ upcoming && data.eventPrice ? (
					<div className="event-list-item__price-container">
						<Link
							to="/contact-us"
							state={{ booking: data.eventTitle }}
							className="button button--primary button--large button--no-margin">
							Book Tickets
						</Link>
						<div className="event-list-item__price">
							{ data.eventPrice ? `£${data.eventPrice}` : '' }
						</div>
					</div>
				) : (
					<div>
						<Link to={ data.eventSlug } className="event-list-item__link">Read More</Link>
					</div>
				) }
			</div>
		</div>
	)
}