import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/Seo'

import DynamicZone from '../components/DynamicZone/DynamicZone'
import EventsList from '../components/Events/EventsList'

export default function eventsPage({ data: { strapi: { eventsPage, events } }, location }) {

	return (
		<>
			<SEO title="Events" description={ eventsPage.seoDescription } pathname={ location.pathname } />

			{ eventsPage && eventsPage.content &&
				<DynamicZone components={ eventsPage.content } />
			}

			{ events &&
				<EventsList data={ events } />
			}
		</>
	)
}

export const query = graphql`
	query eventsPageQuery {
		strapi {
			eventsPage {
				seoDescription
				content {
					__typename
					... on StrapiQuery_ComponentGeneralHeroBanner {
						id
						heroDescription
						heroTitle
						imageFocus
						image {
							url
							imageFile {
								childImageSharp {
									fluid(maxWidth: 1000, maxHeight: 500) {
										...GatsbyImageSharpFluid_noBase64
							 		}
								}
							}
						}
					}
				}
			}
			events(sort: "eventDateEnd:DESC") {
				id
				eventIntro
				eventDateEnd
				eventDateStart
				eventPrice
				eventSlug
				eventTitle
				eventImage {
					url
					imageFile {
						childImageSharp {
							fluid(maxWidth: 500, maxHeight: 500) {
								...GatsbyImageSharpFluid_noBase64
							 }
						}
					}
				}
			}
		}
	}
`